import React, { useState, useEffect } from 'react';
import './UserDashboard.css';
import userService from '../../../services/user.service';

export const UserDashboard = ({ users }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState(users);
    const [noResults, setNoResults] = useState(false);

    useEffect(() => {
        // Si searchTerm está vacío, mostrar todos los usuarios
        if (searchTerm === '') {
            setFilteredUsers(users);
            setNoResults(false);
        }
    }, [searchTerm, users]);

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearch = async (email) => {
        if (email === '') {
            setFilteredUsers(users);
            setNoResults(false);
            return;
        }

        try {
            const response = await userService.getUserByEmail(email);
            if (response.data) {
                setFilteredUsers([response.data]);
                setNoResults(false);
            } else {
                setFilteredUsers([]);
                setNoResults(true);
            }
            console.log("Usuario buscado", response.data);
        } catch (error) {
            console.error('Error al buscar usuario:', error);
            setFilteredUsers([]);
            setNoResults(true);
        }
    };

    return (
        <div className='UserDashboard'>
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Buscar usuarios..."
                    value={searchTerm}   // Sincroniza el valor del input con searchTerm
                    onChange={handleChange}  // Actualiza searchTerm cuando el input cambia
                />
                <button
                    className="search-button"
                    onClick={() => handleSearch(searchTerm)}
                >
                    🔍
                </button>
            </div>

            <div className="UsersContainer">
                {filteredUsers.length === 0 && noResults ? (
                    <p>No se encontraron usuarios.</p>
                ) : (
                    filteredUsers.map((user) => (
                        <div className='user-card' key={user._id}>
                            <h3>{user.name}</h3>
                            <p><strong>Email:</strong> {user.email}</p>
                            <p><strong>Teléfono:</strong> {user.phone}</p>
                            <p><strong>Animales:</strong></p>
                            <button className=''>Añadir rol</button>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};
