import React, { useState, useEffect } from 'react';
import './BlogEditorComponent.css';
import blogService from '../../services/blog.service'; // Asegúrate de importar el servicio correctamente
import Swal from 'sweetalert2';

export const BlogEditorComponent = ({ currentUser, resetBlog }) => {
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [content, setContent] = useState('');
  const [mediaLink, setMediaLink] = useState('');
  const [image, setImage] = useState(null); // Cambiado para almacenar el archivo directamente
  const [date, setDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [imageOption, setImagenOption] = useState(false);
  const [linkOption, setLinkOption] = useState(false);

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    setDate(today);
  }, []);

  const handleCompleted = () => {
    setCompleted(!completed);
  };

  // Maneja la selección del archivo
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file); // Almacena el archivo directamente
    }
  };

  // Maneja el envío del formulario
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!image && !mediaLink) {
        Swal.fire({
            icon: 'error',
            title: 'Debe seleccionar una imagen o ingresar un enlace.',
            showConfirmButton: true,
        });
        return;
    }

    setLoading(true);
    try {
        // Crear una nueva instancia de FormData

        // Crear el objeto postData
        const postData = {
            title,
            subtitle,
            date,
            content,
            completed,
        };

        
        await blogService.addBlogs(postData, image);

        resetBlog();
        Swal.fire({
            position: 'bottom',
            icon: 'success',
            title: 'Se añadió correctamente',
            showConfirmButton: false,
            timer: 2000,
            showClass: {
                popup: 'animate__animated animate__fadeInUp animate__faster',
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutDown animate__faster',
            },
        });

        // Limpia los campos del formulario
        setTitle('');
        setSubtitle('');
        setContent('');
        setImage(null);
        setMediaLink('');
        setDate(new Date().toISOString().split('T')[0]);
    } catch (error) {
        console.error('Error adding blog:', error);
        alert('Failed to add blog. Please try again.');
    } finally {
        setLoading(false);
    }
};









  

  const handleImageOption = (event) => {
    event.preventDefault();
    if (linkOption) {
      Swal.fire({
        position: 'bottom',
        title: 'Opción de Link abierta',
        showConfirmButton: false,
        timer: 2000,
        showClass: {
          popup: 'animate__animated animate__fadeInUp animate__faster',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutDown animate__faster',
        },
      });
    } else {
      setImagenOption(!imageOption);
    }
  };

  const handleLinkOption = (event) => {
    event.preventDefault();
    if (imageOption) {
      Swal.fire({
        position: 'bottom',
        title: 'Opción de imagen abierta',
        showConfirmButton: false,
        timer: 2000,
        showClass: {
          popup: 'animate__animated animate__fadeInUp animate__faster',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutDown animate__faster',
        },
      });
    } else {
      setLinkOption(!linkOption);
    }
  };

  return (
    <div className='BlogEditor'>
      <h2>Blog Editor</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Subtitle"
          value={subtitle}
          onChange={(e) => setSubtitle(e.target.value)}
        />
        <textarea
          placeholder="Content"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          required
        />
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />

        <div className="BtnOptions">
          <button className={imageOption ? "btn btn-danger btnOptionUpload" : "btn btn-primary btnOptionUpload"} onClick={handleImageOption}>
            {imageOption ? "Cancelar" : "Subir imagen"}
          </button>
          <button className={linkOption ? "btn btn-danger btnOptionUpload" : "btn btn-primary btnOptionUpload"} onClick={handleLinkOption}>
            {linkOption ? "Cancelar" : "Subir Link"}
          </button>
        </div>

        {imageOption && (
          <div>
            <label htmlFor="file-input" className="file-label">
              Selecciona una imagen
            </label>
            <input
              id="file-input"
              type="file"
              accept="image/webp"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </div>
        )}

        {linkOption && (
          <input
            type="url"
            placeholder="O ingresa un enlace de YouTube"
            value={mediaLink}
            onChange={(e) => setMediaLink(e.target.value)}
          />
        )}

        <div className="CheckBoxContainer">
          <div className="CheckBox">
            <input type="checkbox" aria-label={completed ? 'Completado' : 'No completado'} onClick={() => handleCompleted()} />
            <label>{completed ? 'Completado' : 'No completado'}</label>
          </div>
        </div>

        {image && <img src={URL.createObjectURL(image)} alt="Preview" className="image-preview" />} {/* Muestra la vista previa de la imagen */}
        <button type="submit" className="btnSubirBlog" disabled={loading}>
          {loading ? 'Subiendo...' : 'Subir'}
        </button>
      </form>
    </div>
  );
};
