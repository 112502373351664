import React, { useEffect, useState } from 'react';
import authService from '../../services/auth.service';
import "./NavbarPetPass.css";
import { Link } from 'react-router-dom';
import burguerMenu from "../../assets/menu.png";
import 'animate.css';

export const NavbarPetPass = ({ currentUser }) => {
  const [user, setUser] = useState(null);
  const [optionsNavbar, setOptionsNavbar] = useState(false);

  const hasBlogEditorRole = currentUser?.roles?.some(role => role.name === 'blogEditor');
  const hasUserRole = currentUser?.roles?.some(role => role.name === 'user');
  const hasAdminRole = currentUser?.roles?.some(role => role.name === 'admin');

  useEffect(() => {
    const fetchUser = async () => {
      const userData = await authService.getCurrentUser();
      if (userData) {
        setUser(userData);
      }
    };

    fetchUser();
  }, []);

  const logOut = () => {
    authService.logout();
    setUser(null);
    window.location.reload();
  };

  const toggleNavbar = () => {
    setOptionsNavbar(!optionsNavbar);
  };

  return (
    <div className="navbar-petpass">
      <div className='containerContent'>
        <div className="petPassLogo">
          <img src="https://cdn-icons-png.flaticon.com/512/64/64431.png" alt="Logo" />
          <h1>Pet-passport</h1>
        </div>

        {user ? (
          <>
            <div className='opcionesNav'>

              {hasBlogEditorRole && <Link to="/blog">Editar Blog</Link>}
              {hasUserRole && <Link to="/blog">Blog</Link>}
              {hasAdminRole && <Link to="/blog">Editar Blog</Link>}
              {hasAdminRole && <Link to="/DashBoard">Dashboard</Link>}


              <Link to="/home">Home</Link>
              <Link to="/profile">Perfil</Link>
              <button onClick={logOut} aria-label={"LogOut"}>LogOut</button>
            </div>

            <div className='opcionesNavMobile'>
              <div className="burguerMenu" onClick={toggleNavbar}>
                <img src={burguerMenu} alt="Menu" />
              </div>

              {optionsNavbar && (


                <div className='mobileMenu animate__fadeInDownBig'>

                  <span className='Cerrar' onClick={toggleNavbar}>X</span>

                  <div className="optionsContainerMobile">

                    <Link onClick={toggleNavbar} to="/home">Home</Link>
                    <Link onClick={toggleNavbar} to="/profile">Perfil</Link>
                    {hasBlogEditorRole && <Link to="/blog">Editar Blog</Link>}
                    {hasUserRole && <Link to="/blog">Blog</Link>}
                    {hasAdminRole && <Link to="/blog">Editar Blog</Link>}
                    {hasAdminRole && <Link to="/DashBoard">Dashbord</Link>}

                    <button onClick={logOut} aria-label={"LogOut"}>LogOut</button>


                  </div>



                </div>
              )}
            </div>
          </>


        ) : (
          <div className='ContainerNoLog'>
            <div className='opcionesNav logOut'>

              <Link to="/login"><button aria-label={"Login"}>Login</button></Link>
              <Link to="/register"><button aria-label={"Register"}>Register</button></Link>
            </div>


            <div className='opcionesNavMobile'>
              <div className="burguerMenu" onClick={toggleNavbar}>
                <img src={burguerMenu} alt="Menu" />
              </div>

              {optionsNavbar && (


                <div className='mobileMenu animate__fadeInDownBig'>

                  <span className='Cerrar' onClick={toggleNavbar}>X</span>

                  <div className="optionsContainerMobile noLog">

                    <Link onClick={toggleNavbar} to="/login" aria-label={"Login"}><button>Login</button></Link>
                    <Link onClick={toggleNavbar} to="/register" aria-label={"Register"}><button>Register</button></Link>



                  </div>



                </div>
              )}
            </div>






          </div>


        )}
      </div>
    </div>
  );
};
