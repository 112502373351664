import React, { useState } from 'react';
import "./NoLoged.css";
import Login from "../login.component";
import Register from "../register.component";
import { Link } from 'react-router-dom';

export const NoLog = () => {
 

  return (
    <div className="containerTodo">



    
    <div className=' NoLoged'>
      <h1>No estas logueado</h1>
      <p>No te encontras logueado por favor inicia sesión o crea una cuenta.</p>

      
        <div className="ButtonOptions">

          <Link to={"/login"}><button aria-label={"Login"}>Login</button></Link>
          <Link to={"/Register"}><button aria-label={"Register"}>Register</button></Link>
          
          
        </div>
   

      
    </div>

    </div>
  );
};
