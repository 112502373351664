import React, { useEffect } from 'react';
import "./EditProfile.css";
import userService from '../../services/user.service';
import Swal from 'sweetalert2';
import BaseFormComponent from '../baseForm.component';
import * as Yup from 'yup';

export const EditProfile = ({ currentUser, onClose, onUpdate }) => {

  const handleSubmit = async (values) => {
    console.log("Datos enviados:", values);

    try {
      const response = await userService.patchProfileUser(values);
      console.log("Respuesta del servidor:", response.data);
      Swal.fire({
        position: "bottom",
        icon: "success",
        title: "Se envió correctamente",
        showConfirmButton: false,
        timer: 2000,
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `
        }
      });

      // Llamar onUpdate con el usuario actualizado
      onUpdate(response.data); // Suponiendo que response.data es el usuario actualizado

      onClose(); // Cerrar el formulario después de actualizar correctamente
      
    } catch (error) {
      console.error("Error al enviar la solicitud PATCH:", error);
      Swal.fire({
        position: "bottom",
        icon: "error",
        title: "Hubo un error",
        showConfirmButton: false,
        timer: 2000,
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `
        }
      });
    }
  };

  const fields = [
    { key: 'name', label: 'Nombre', type: 'text' },
    { key: 'email', label: 'Email', type: 'email' },
    { key: 'phone', label: 'Número', type: 'text' }
  ];

  const initialValues = {
    name: currentUser?.name || '',
    email: currentUser?.email || '',
    phone: currentUser?.phone || ''
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('El nombre es obligatorio'),
    email: Yup.string().email('Email inválido').required('El email es obligatorio'),
    phone: Yup.string().required('El número es obligatorio')
  });

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [onClose]);

  return (
    <div className='EditProfile'>
      <h2>Editar Perfil</h2>

      <BaseFormComponent
        fields={fields}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        className="formEditProfile"
      />

      <span onClick={onClose} className='Cerrar'>X</span>
    </div>
  );
};
