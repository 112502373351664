import React, { useState, useEffect, useRef } from 'react';
import rolesService from '../../../../services/roles.service';
import "./RoleForm.css";
import Swal from 'sweetalert2';

export const RoleForm = ({ handleRolForm, roleToEdit }) => {
    const [role, setRole] = useState({
        name: '',
        permissions: [],
        allowed_routes: []
    });
    const [permissions, setPermissions] = useState('');
    const [permissionsList, setPermissionsList] = useState([]);
    const [selectedRoutes, setSelectedRoutes] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const refComponent = useRef(null);


    useEffect(() => {
        // Desplazar la vista al componente cuando se monte
        if (refComponent.current) {
            refComponent.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);


    useEffect(() => {
        if (roleToEdit) {
            setRole(roleToEdit); // Rellena el formulario con los datos del rol a editar
            setPermissionsList(roleToEdit.permissions || []);
            setSelectedRoutes(roleToEdit.allowed_routes || []);
        }
    }, [roleToEdit]);

    const handlePermissionsChange = (event) => {
        setPermissions(event.target.value);
    };

    const handlePermissionsSubmit = () => {
        const permissionsArray = permissions.split(',').map(permission => permission.trim()).filter(permission => permission);
        if (permissionsArray.length > 0) {
            setPermissionsList([...permissionsList, ...permissionsArray]);
            setPermissions('');
        }
    };

    const handleRouteSelection = (event) => {
        const selectedValue = event.target.value;
        if (!selectedRoutes.includes(selectedValue) && selectedValue !== '') {
            setSelectedRoutes([...selectedRoutes, selectedValue]);
        }
    };

    const handleDeletePermission = (index) => {
        const updatedPermissions = permissionsList.filter((_, i) => i !== index);
        setPermissionsList(updatedPermissions);
    };

    const handleDeleteRoute = (index) => {
        const updatedRoutes = selectedRoutes.filter((_, i) => i !== index);
        setSelectedRoutes(updatedRoutes);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!role.name.trim()) {
            setError('El nombre del rol es requerido');
            return;
        }

        if (selectedRoutes.length === 0) {
            setError('Debe seleccionar al menos una ruta');
            return;
        }

        if (permissionsList.length === 0) {
            setError('Debe agregar al menos un permiso');
            return;
        }

        const roleData = {
            id: role._id,
            name: role.name,
            permissions: permissionsList,
            allowed_routes: selectedRoutes
        };

        try {
            if (roleToEdit) {
                // Actualiza rol existente
                await rolesService.updateRole(role._id, roleData);
                setSuccess('Rol actualizado exitosamente');
            } else {
                // Agrega nuevo rol
                await rolesService.postRole(roleData);
                setSuccess('Rol agregado exitosamente');
            }
            handleRolForm(); // Cierra el formulario
        } catch (error) {
            setError('Error al procesar el rol');
            console.error('Error:', error);
        }

        window.location.reload()
    };

    return (
        <div className='backgroundRoleForm' ref={refComponent}>
            <div className="RoleForm CrudEvent">
                <h2>{roleToEdit ? 'Editar Rol' : 'Agregar Rol'}</h2>

                <form className="formEditProfile" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Ingrese nombre del rol"
                        value={role.name}
                        onChange={(e) => setRole({ ...role, name: e.target.value })}
                    />

                    <label htmlFor="routes">Rutas Disponibles</label>
                    <select name="routes" id="routes" onChange={handleRouteSelection}>
                        <option value="">Seleccione una ruta</option>
                        <option value="/api/v1/animals/*">/api/v1/animals/*</option>
                        <option value="/api/v1/animal/*">/api/v1/animal/*</option>
                        <option value="/api/v1/events/*">/api/v1/events/*</option>
                        <option value="/api/v1/blog/*">/api/v1/blog/*</option>
                        <option value="/api/v1/admin/*">/api/v1/admin/*</option>
                        <option value="/api/v1/admin/roles/*">/api/v1/admin/roles/*</option>
                    </select>

                    <label htmlFor="selectedRoutes">Rutas seleccionadas</label>
                    <ul id="selectedRoutes">
                        {selectedRoutes.length > 0 ? (
                            selectedRoutes.map((route, index) => (
                                <li key={index}>
                                    {route}
                                    <button type="button" onClick={() => handleDeleteRoute(index)}>Eliminar</button>
                                </li>
                            ))
                        ) : (
                            <li>No se han seleccionado rutas.</li>
                        )}
                    </ul>

                    <label htmlFor="permissions">Permisos (separados por comas)</label>
                    <input
                        type="text"
                        name="permissions"
                        id="permissions"
                        placeholder="Ingrese permisos"
                        value={permissions}
                        onChange={handlePermissionsChange}
                    />
                    <button type="button" onClick={handlePermissionsSubmit} className='add-role-button'>Agregar Permisos</button>

                    <label htmlFor="permissionsList">Permisos agregados</label>
                    <ul id="selectedRoutes">
                        {permissionsList.length > 0 ? (
                            permissionsList.map((permission, index) => (
                                <li key={index}>
                                    {permission}
                                    <button type="button" onClick={() => handleDeletePermission(index)}>Eliminar</button>
                                </li>
                            ))
                        ) : (
                            <li>No se han agregado permisos.</li>
                        )}
                    </ul>

                    <button type="submit" className='add-role-button'>
                        {roleToEdit ? 'Actualizar Rol' : 'Agregar Rol'}
                    </button>

                    <span className='Cerrar' onClick={handleRolForm}>x</span>
                </form>

                {error && <p className="error">{error}</p>}
                {success && <p className="success">{success}</p>}
            </div>
        </div>
    );
};
