import React from 'react';
import { useParams } from 'react-router-dom'; 
import AnimalDetail from '../../animalDetail.component';

export const CrudAnimal = () => {
  const { id } = useParams(); 
 

  return (
    <div>
      <AnimalDetail animalId={id} />
    </div>
  );
};
