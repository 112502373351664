import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { withRouter } from "../common/with-router"; 

class BaseForm extends Component {
  constructor(props) {
    super(props);

    const initialState = {
      successful: false,
      message: ""
    };

    this.fieldRefs = {};
    this.props.fields.forEach((field) => {
      this.fieldRefs[field.key] = React.createRef();
      initialState[field.key] = this.props.initialValues[field.key] || "";
    });

    this.state = initialState;
  }

  componentDidMount() {
    // Foco en el primer campo
    if (this.fieldRefs[this.props.fields[0].key].current) {
      this.fieldRefs[this.props.fields[0].key].current.focus();
    }
  }

  onChange = (e) => {
    console.log("Se activa el evento onChange para el atributo " + e.target.name);
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    console.log("Se activa la función handleSubmit, los valores son:", values);

    this.setState({ message: "Loading...", successful: false });

    try {
      await this.props.onSubmit(values); 
      this.setState({ message: "Submitted successfully", successful: true });
      this.props.router.navigate(this.props.navigate_to);
    } catch (error) {
      const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      setFieldError("general", resMessage);
    } finally {
      setSubmitting(false); 
    }
  };

  render() {
    const { fields, initialValues, validationSchema, className } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
      >
        {({ isSubmitting, errors }) => (
          <Form className={className}>
            {fields.map((field) => (
              <div key={field.key} className="mb-3 FieldContainer">
                <label htmlFor={field.key} className="form-label">{field.label}</label>
                <Field
                  innerRef={this.fieldRefs[field.key]} 
                  id={field.key}
                  as={field.type === 'textarea' ? 'textarea' : 'input'}
                  placeholder={field.placeholder || ''}
                  type={field.type}
                  name={field.key}
                  className={`form-contRole ${errors[field.key] ? 'is-invalid' : ''}`}
                  aria-describedby={`${field.key}-error`}
                />
                <ErrorMessage
                  name={field.key}
                  component="div"
                  className="invalid-feedback"
                  id={`${field.key}-error`}
                />
              </div>
            ))}
            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'aceptar'}
            </button>

            {this.state.message && (
              <div className={`alert ${this.state.successful ? 'alert-success' : 'alert-danger'} mt-3`} Rolee="alert">
                {this.state.message}
              </div>
            )}

            {errors.general && (
              <div className="alert alert-danger mt-3" Rolee="alert">
                {errors.general}
              </div>
            )}
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(BaseForm);
