import React, { useEffect, useState } from 'react';
import "./RolesCrud.css";
import { RoleForm } from './RoleForm/RoleForm';
import rolesService from '../../../services/roles.service';
import Swal from 'sweetalert2';

export const RolesCrud = ({ roles, onDeleteRole }) => {
    const [rolForm, setRolForm] = useState(false);
    const [editingRole, setEditingRole] = useState(null); // Estado para manejar el rol que se está editando
    const [RolesList, setRoles] = useState([]);

    useEffect(() => { 
        // Obtener la lista de roles al montar el componente
        fetchRoles();
    }, []);

    // Función para obtener la lista de roles
    const fetchRoles = async () => {
        try {
            const currentRoles = await rolesService.getRoles();
            setRoles(currentRoles.data);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };

    const handleDelete = async (role) => {
        try {
            const response = await rolesService.deleteRole(role._id);
            console.log('Role deleted:', response);

            Swal.fire({
                position: "bottom",
                icon: "success",
                title: "Se eliminó correctamente",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                    popup: 'animate__animated animate__fadeInUp animate__faster'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutDown animate__faster'
                }
            });

            // Actualizar la lista de roles después de eliminar
            fetchRoles();
            window.location.reload()
        } catch (error) {
            console.error('Error deleting role:', error);
            window.location.reload()
        }
    };

    const handleEdit = (role) => {
        setEditingRole(role); // Establece el rol que se va a editar
        setRolForm(true); // Abre el formulario de edición
        
    };

    const handleRolForm = () => {
        setRolForm(!rolForm);
        setEditingRole(null); // Reinicia el rol que se está editando
    };

    return (
        <div className='RolesCrud'>
            <h2>Roles</h2>
            <button className='add-role-button' onClick={handleRolForm}>Agregar Rol</button>
            <ul>
                {rolForm && (
                    <RoleForm
                        roles={RolesList}
                        handleRolForm={handleRolForm}
                        roleToEdit={editingRole} // Pasa el rol a editar al formulario
                        onRoleUpdated={fetchRoles} // Actualizar la lista de roles después de editar
                    />
                )}

                {RolesList && RolesList.length > 0 ? (
                    RolesList.map((role, index) => (
                        <li key={index} className='role-item'>
                            <div className="item">
                                <h3>{role.name}</h3>
                                <a>permissions: {role.permissions.join(', ')}</a>
                                <a>
                                    Allow Routes: {role.allowed_routes && role.allowed_routes.length > 0 ? (
                                        <ul>
                                            {role.allowed_routes.map((route, routeIndex) => (
                                                <li key={routeIndex} className="route-item">{route}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <span>No routes available</span>
                                    )}
                                </a>
                            </div>
                            <div className="btnDeleteS">
                                <button
                                    className='edit-role-button'
                                    onClick={() => handleEdit(role)} // Llama a handleEdit con el rol
                                >
                                    Editar
                                </button>

                                <button className='delete-role-button' onClick={() => handleDelete(role)}>Eliminar</button>
                            </div>
                        </li>
                    ))
                ) : (
                    <p>No roles found.</p>
                )}
            </ul>
        </div>
    );
};
