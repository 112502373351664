import axios from "axios";
import { authHeader } from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

class RolesService {
    
    getRoles() {
        return axios.get(API_URL + 'admin/roles', { headers: authHeader() });
    }

    getAllUsers() {
        return axios.get(API_URL + 'admin/users', { headers: authHeader() });
    }

    postRole(role) {
        return axios.post(API_URL + 'admin/roles', role, { headers: authHeader() });
    }

    deleteRole(id) {
        return axios.delete(API_URL + 'admin/role/' + id, { headers: authHeader() });
    }

    updateRole(id, role) {
        console.log(role)
        return axios.patch(API_URL + 'admin/role/' + id, role, { headers: authHeader() });
    }
}

export default new RolesService();
