import React, { useState, useEffect, useRef } from 'react';
import './EventTable.css';
import { CrudEvent } from '../CrudEvent/CrudEvent';
import Swal from 'sweetalert2';
import animalService from '../../../../services/animal.service';
import { EventVisualizacion } from '../EventVisualizacion/EventVisualizacion';
import { DeleteEventSign } from '../DeleteEventSign/DeleteEventSign';

export const EventTable = ({ events, updateEventDetails, forceReloadComponent }) => {
  const [activeEvent, setActiveEvent] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [showCrudEvent, setShowCrudEvent] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null); // Estado para el evento seleccionado
  const [showDeleteSign, setShowDeleteSign] = useState(false); // Estado para mostrar el DeleteEventSign
  const menuRef = useRef(null);

  const handleButtonClick = (index, event) => {
    const buttonRect = event.target.getBoundingClientRect();
    setMenuPosition({
      top: buttonRect.bottom + window.scRolelY,
      left: buttonRect.left + window.scRolelX,
    });

    if (activeEvent === index) {
      setActiveEvent(null);
    } else {
      setActiveEvent(index);
    }
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setActiveEvent(null);
    }
  };

  const handleEditClick = (event) => {
    setSelectedEvent(event);
    setShowCrudEvent(true);
    setActiveEvent(null);
  };

  const handleButtonSign = (event) => {
    setSelectedEvent(event);
    setShowDeleteSign(true);
  };

  const handleDeleteEvent = async (id) => {
    try {
      const response = await animalService.deleteEventbyId(id);
      Swal.fire({
        position: 'bottom',
        icon: 'success',
        title: 'Se eliminó correctamente',
        showConfirmButton: false,
        timer: 2000,
        showClass: {
          popup: 'animate__animated animate__fadeInUp animate__faster',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutDown animate__faster',
        },
      });
      setShowDeleteSign(false); // Oculta el DeleteEventSign después de la eliminación
      forceReloadComponent();
    } catch (error) {
      console.error('Error eliminando evento:', error);
      Swal.fire({
        position: 'bottom',
        icon: 'error',
        title: 'Hubo un error',
        showConfirmButton: false,
        timer: 2000,
        showClass: {
          popup: 'animate__animated animate__fadeInUp animate__faster',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutDown animate__faster',
        },
      });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (menuRef.current && activeEvent !== null) {
      menuRef.current.focus();
    }
  }, [activeEvent]);

  return (
    <div className="containerTableAll">
      <div className="containerTableAnimal">
        <table className="text-center tableAnimal">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Tipo</th>
              <th>Descripción</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {events ? (
              events.map((event, index) => (
                <tr key={index}>
                  <td>{event.name}</td>
                  <td>{event.type}</td>
                  <td>{event.description}</td>
                  <td className="accionTd">
                    <button
                      onClick={(e) => handleButtonClick(index, e)}
                      aria-haspopup="true"
                      aria-expanded={activeEvent === index}
                      aria-label={`Opciones para ${event.name}`}
                    >
                      ...
                    </button>
                    {activeEvent === index && (
                      <div
                        className="optionsMenu"
                        ref={menuRef}
                        style={{ top: `${menuPosition.top}px`, left: `${menuPosition.left}px` }}
                        tabIndex="-1"
                      >
                        <button
                          onClick={() => handleEditClick(event)}
                          aria-label={`Editar evento ${event.name}`}
                        >
                          Editar evento
                        </button>
                        <button
                          onClick={() => handleButtonSign(event)}
                          className='btnEliminar'
                          aria-label={`Eliminar evento ${event.name}`}
                        >
                          Eliminar evento
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No hay eventos</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showCrudEvent && (
        <EventVisualizacion
          event={selectedEvent}
          onClose={() => setShowCrudEvent(false)}
          onUpdate={updateEventDetails}
        />
      )}

      {showDeleteSign && (
        <DeleteEventSign
          event={selectedEvent}
          onDelete={handleDeleteEvent}
          onClose={() => setShowDeleteSign(false)}
        />
      )}
    </div>
  );
};
