import React from 'react';
import "./Btnwhatsapp.css";
import whatsappImagen from "../../assets/whatsapp.png";

export const Btnwhatsapp = () => {
  return (
    <div className='BtnWhatsapp'>
      <button
        className="whatsapp-button"
        aria-label="Contactar vía WhatsApp"
        onClick={() => window.location.href = "https://wa.me/+5493513320801"} // Enlace al número de WhatsApp
      >
        <img src={whatsappImagen} alt="Botón de WhatsApp" />
      </button>
    </div>
  );
};
