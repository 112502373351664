import React, { Component } from "react";
import { Link } from "react-router-dom";
import AnimalForm from "./animalForm.Component"
import AnimalService from "../../services/animal.service";
import { Button } from 'react-bootstrap';
import "../animal/ComponentStyles/animal.component.css"

export default class AnimalList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animals: [],
      redirect: null,
      showAnimalForm: false
    };
    this.handleClick = this.handleClick.bind(this)
    this.getAnimals = this.getAnimals.bind(this)
    this.updateList = this.updateList.bind(this)
  }
  handleClick(e) {
    e.preventDefault()
    this.setState({ showAnimalForm: true })
  }

  async getAnimals() {
    const animals = await AnimalService.getAnimals();
    this.setState({ animals: animals });
  }

  async componentDidMount() {
    await this.getAnimals()
  }
  async updateList() {
    this.setState({ showAnimalForm: false })
    await this.getAnimals()
  }

  render() {
    const animals = this.state.animals;
    console.log(animals)

    return (
      <div className="containerTableAll">
        <h2>tus animales </h2>
        <div className=" containerTableAnimal">
          {animals.length == 0 ? (
            
              
              <div className="noAnimalSign">


                  <div className="Sign">

                      <p>No hay animales</p>
                    
                    
                    
                     </div>
                
                
                
                
                  </div>


          ): (
            <table className=" text-center tableAnimal">
            <thead>
              <tr>
                <th>nombre</th>
                <th>tipo</th>
                <th> descripción</th>
                <th> Ver Mas</th>

                
              </tr>
            </thead>
            <tbody>
              {animals ? (
                animals.map((animal, index) => (
                  <tr key={index}>
                    <td>{animal.name}</td>
                    <td> {animal.type}</td>
                    <td>{animal.description} </td>
                    <td>
                      <Link to={`/animal/${animal._id}`} style={{ color: 'blue', textDecoration: 'inherit' }}> Ver Mas </Link>
                      
                      </td>             
                  </tr>
                ))
              ) : (
                <p>No hay animales</p>
              )

              }

            </tbody>
          </table>

            



          )}
          
        </div>

        <div className="text-right">
          <Button variant="primary" onClick={this.handleClick}>
            Nuevo Animal
          </Button>

          {/* Renderiza el componente AnimalForm si showAnimalForm es true */}
          {this.state.showAnimalForm && <AnimalForm onAdd={this.updateList} />}
        </div>
      </div>



    );
  }
}
