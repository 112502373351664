import React, { useEffect, useRef } from 'react';
import "./CrudEvent.css";
import BaseForm from '../../../baseForm.component';
import * as Yup from 'yup';
import animalService from '../../../../services/animal.service';
import Swal from 'sweetalert2';

export const CrudEvent = ({ animal, onClose, onUpdateAnimal }) => {
    const firstInputRef = useRef(null);

    const handleSubmit = async (values) => {
        const eventData = {
            name: values.nameEvent,
            type: values.Tipo,
            description: values.Obs,
            startDate: values.startDate || new Date().toISOString(),
            endDate: values.endDate || new Date().toISOString(),
        };

        try {
            const response = await animalService.postEvent(animal._id, eventData);
            console.log('Respuesta del servidor:', response);
            Swal.fire({
                position: "bottom",
                icon: "success",
                title: "El evento se creó correctamente",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                    popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                    `
                },
                hideClass: {
                    popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
                    `
                }
            });
            onUpdateAnimal();
            onClose();
        } catch (error) {
            console.error('Error al enviar la solicitud:', error);
            Swal.fire({
                position: "bottom",
                icon: "error",
                title: "Hubo un error",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                    popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                    `
                },
                hideClass: {
                    popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
                    `
                }
            });
        }
    };

    const fields = [
        { key: 'nameEvent', label: 'Nombre de Evento', type: 'text', inputRef: firstInputRef },
        { key: 'Obs', label: 'Observación', type: 'textarea' },
        { key: 'Tipo', label: 'Tipo', type: 'text' },
        { key: 'startDate', label: 'Fecha de inicio (opcional)', type: 'datetime-local' },
        { key: 'endDate', label: 'Fecha final (opcional)', type: 'datetime-local' }
    ];

    const initialValues = {
        nameEvent: '',
        Obs: '',
        Tipo: '',
        startDate: '',
        endDate: ''
    };

    const validationSchema = Yup.object().shape({
        nameEvent: Yup.string().required('El nombre es obligatorio'),
        Obs: Yup.string().required('La observación es obligatoria'),
        Tipo: Yup.string().required('El tipo es obligatorio'),
    });

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleEscape);

        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [onClose]);

    return (
        <div className='CrudEvent'>
            <h2>Añadir evento</h2>
            <BaseForm
                fields={fields}
                initialValues={initialValues}
                className="formEditProfile"
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                
            />
            <span className='Cerrar' onClick={onClose}>X</span>
        </div>
    );
};
