import React, { useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import animalService from "../../services/animal.service";
import BaseForm from "../baseForm.component";

const animalValidationSchema = Yup.object().shape({
  name: Yup.string().required("El nombre es requerido!"),
  type: Yup.string().required("El tipo es requerido!"),
  description: Yup.string().required("La descripcion es requerida!")
});

class AnimalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true
    };
    this.closeModal = this.closeModal.bind(this);
    this.firstInputRef = React.createRef(); 
  }

  closeModal() {
    this.setState({ showModal: false });
    this.props.onAdd();
  }

  async addAnimal(values, { setSubmitting, setFieldError }) {
    const data = {
      name: values.name,
      type: values.type,
      description: values.description,
      birthDate: null,
      dateOfRegistration: null,
      events: []
    };

    try {
      await animalService.AddAnimal(data);
      this.closeModal();
    } catch (error) {
      console.log(error);
      setFieldError("general", "An error occurred while adding the animal.");
      setSubmitting(false);
    }
  }

  componentDidMount() {
    
    if (this.firstInputRef.current) {
      this.firstInputRef.current.focus();
    }
  }

  render() {
    return (
      <Modal show={this.state.showModal} onHide={this.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Animal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12">
            <div className="card card-container">
              <Formik
                initialValues={{ name: "", type: "", description: "" }}
                validationSchema={animalValidationSchema}
                onSubmit={(values, actions) => this.addAnimal(values, actions)}
              >
                {({ isSubmitting, errors }) => (
                  <Form>
                    <div className="form-group">
                      <label htmlFor="name">Nombre</label>
                      <Field
                        type="text"
                        name="name"
                        innerRef={this.firstInputRef} 
                        className="form-contRole"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="alert alert-danger"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="type">Tipo</label>
                      <Field
                        type="text"
                        name="type"
                        className="form-contRole"
                      />
                      <ErrorMessage
                        name="type"
                        component="div"
                        className="alert alert-danger"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="description">Descripción</label>
                      <Field
                        type="text"
                        name="description"
                        className="form-contRole"
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="alert alert-danger"
                      />
                    </div>

                    <div className="form-group">
                      <Button
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={isSubmitting}
                        style={{ width: "200px", margin: "20px auto" }}
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Añadir animal</span>
                      </Button>
                    </div>

                    {errors.general && (
                      <div className="form-group">
                        <div className="alert alert-danger" Rolee="alert">
                          {errors.general}
                        </div>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default AnimalForm;
