import React, { Component } from "react";
import BaseForm from "./baseForm.component";
import authService from "../services/auth.service";
import "./styles/register.component.css";
import * as Yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default class Register extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const registerValidationSchema = Yup.object().shape({
      name: Yup.string().required("This field is required!"),
      phone: Yup.string()
        .required("This field is required!")
        .test('is-valid-phone', 'Phone number is not valid.', value => {
          if (!value) return false;
          const phoneNumber = parsePhoneNumberFromString(value, 'AR'); // Cambia 'US' por el código de país adecuado
          return phoneNumber && phoneNumber.isValid();
        }),
      email: Yup.string().email('This is not a valid email.').required('This field is required!'),
      password: Yup.string().min(6, 'The password must be between 6 and 40 characters.').max(40, 'The password must be between 6 and 40 characters.').required("This field is required!")
    });

    const fields = [
      { key: "name", label: "Nombre", type: "text", placeholder: "Ingresa tu nombre" },
      { key: 'phone', label: 'Phone', type: 'number', placeholder: '0011223344' },
      { key: 'email', label: 'Email', type: 'email', placeholder: 'Ingresa tu correo electrónico' },
      { key: 'password', label: 'Password', type: 'password', placeholder: 'Crea una contraseña' }
    ];
    

    const initialValues = {
      name: "",
      phone: "",
      email: "",
      password: ""
    };

    const onSubmit = async (values) => {
      const valuesToSubmit = {
        ...values,
        phone: String(values.phone)  // Convertir el número de teléfono a cadena
      };

      try {
        await authService.register(valuesToSubmit);
        console.log('Registration successful!');
        // Aquí podrías manejar la redirección a la página de login u otra acción necesaria
      } catch (error) {
        console.error('Error registering:', error);
        // Manejo de errores, por ejemplo, mostrar un mensaje al usuario
      }
    };

    const navigate_to = "/login";

    return (
      <div className="col-md-12 registerContainer">
        <div className="Register">
          <h1>Registrarse</h1>
          <BaseForm
            fields={fields}
            initialValues={initialValues}
            onSubmit={onSubmit}
            className="formRegister"
            navigate_to={navigate_to}
            validationSchema={registerValidationSchema}
          />
        </div>
      </div>
    );
  }
}
