import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import BaseFormComponent from '../../../baseForm.component';
import animalService from '../../../../services/animal.service';
import Swal from 'sweetalert2';

export const AnimalSector = ({ animal, onClose, onUpdate }) => {
  const nombreAnimalRef = useRef(null);

  useEffect(() => {
    if (nombreAnimalRef.current) {
      nombreAnimalRef.current.focus();
    }

    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [onClose]);

  if (!animal) {
    return <div>Loading...</div>;
  }

  const fields = [
    { key: 'nombreAnimal', label: 'Nombre', type: 'text', inputRef: nombreAnimalRef },
    { key: 'descripcion', label: 'Descripción', type: 'text' },
    { key: 'tipo', label: 'Tipo', type: 'text' },
    { key: 'Birthday', label: 'Birthday', type: 'date' },
  ];

  const initialValues = {
    nombreAnimal: animal.name || "",
    descripcion: animal.description || "",
    tipo: animal.type || "",
    Birthday: animal.birthday || ""
  };

  const validationSchema = Yup.object().shape({
    nombreAnimal: Yup.string().required('El nombre es obligatorio'),
    descripcion: Yup.string().required('La descripción es obligatoria'),
  });

  const handleEditAnimal = async (values) => {
    const { nombreAnimal, descripcion, tipo, Birthday } = values;
    const edit = {
      id: animal._id,
      type: tipo,
      name: nombreAnimal,
      description: descripcion,
      birthDate: Birthday || animal.birthDate,
      dateOfRegistration: animal.dateOfRegistration,
      events: animal.events
    };

    try {
      const response = await animalService.editAnimal(animal._id, edit);
      console.log(response);
      onUpdate();
      Swal.fire({
        position: "bottom",
        icon: "success",
        title: "El evento se creo correctamente",
        showConfirmButton: false,
        timer: 2000,
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `
        }
      });

      onClose();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className='CrudEvent'>
      <h1>Editar animal</h1>
      <BaseFormComponent
        fields={fields}
        initialValues={initialValues}
        className="formEditProfile"
        validationSchema={validationSchema}
        onSubmit={handleEditAnimal}
      />
      <span className='Cerrar' onClick={onClose} aria-label={"Cerrar"}>X</span>
     
    </div>
  );
};
