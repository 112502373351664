import axios from 'axios';
import { authHeader } from './auth-header';

const API_URL = process.env.REACT_APP_API_URL;

class AnimalService {
    async getAnimals() {
        const response = await axios.get(API_URL + 'animals', { headers: authHeader() });
        console.log(response.data)
        return response.data;
    }

    async getAnimalbyId(id) {
        const response = await axios.get(`${API_URL}animal/${id}`, { headers: authHeader() });
        console.log(response.data)
        return response.data
    }


    async AddAnimal(animal) {
        const response = await axios.post(API_URL + 'animal', animal, { headers: authHeader() })
        console.log(response)
        return response
    }

    async editAnimal(id,values) {
        const response = await axios.patch(API_URL + `animal/${id}`, values, {headers: authHeader()}  )
        return response
    }

    async postEvent(id, event){
        const response =  await axios.post(API_URL + `event/${id}?animal_id=${id}`, event, {headers: authHeader()});
        console.log(response)
        return response
    }

    async getEventbyId(id){
        const response = await axios.get(API_URL + `events/${id}`, {headers: authHeader()});
        console.log(response)
        return response.data
    }

    async patchEventbyId(id,values){
        const response = await axios.patch(API_URL + `event/${id}`,values,  {headers: authHeader()} )
        console.log(response)
        return response 
    }

    async deleteEventbyId(id){
        const response = await axios.delete(API_URL+ `event/${id}`, {headers: authHeader()} )
        console.log(response)
        return response
    }
}
export default new AnimalService()