import axios from "axios";
import {authHeader, registerHeader}  from "../services/auth-header";
 const API_URL = process.env.REACT_APP_API_URL;
                
    
class AuthService {
  login(username, password) {
    const customConfig =  {
      headers: {
          "Content-Type": "application/x-www-form-urlencoded"
}
      }
    const qs = require('qs');
    const data= qs.stringify({ "username": username, "password": password})
    return axios
      .post(API_URL+'auth/jwt/login' , data, customConfig )
      .then(response => {
           localStorage.setItem("accessToken", JSON.stringify(response.data.access_token).replace(/^"(.*)"$/, '$1'));
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('accessToken')
    localStorage.removeItem("user");
  }

  register(data) {
    return axios.post(API_URL + "auth/register", data, {headers: registerHeader()});
  }

      async getCurrentUser() {
        try {
    const response = await axios.get(API_URL +"users/me", {headers: authHeader()})
localStorage.setItem("user", JSON.stringify(response.data))      
      return response.data;
        }
        catch (error) {
          console.log(error);
if(error.response.status===401){
  localStorage.removeItem('accessToken');
    localStorage.removeItem("user");
    return undefined;
}
  }
}
}
export default new AuthService();
