import React, { useState, useEffect } from 'react';
import animalService from '../../services/animal.service';
import { CrudEvent } from './AnimalVisualizacion/CrudEvent/CrudEvent';
import "./ComponentStyles/animalDetailComponent.css"
import { EventVisualizacion } from './AnimalVisualizacion/EventVisualizacion/EventVisualizacion';
import { AnimalSector } from './AnimalVisualizacion/AnimalSector/AnimalSector';
import Swal from 'sweetalert2';
import { EventTable } from './AnimalVisualizacion/EventTable/EventTable';
import { DeleteEventSign } from './AnimalVisualizacion/DeleteEventSign/DeleteEventSign';

const AnimalDetail = ({ animalId }) => {
    const [animal, setAnimal] = useState(null);
    const [events, setEvents] = useState(null);
    const [showCrudEvent, setShowCrudEvent] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null); // Estado para el evento seleccionado
    const [reloadComponent, setReloadComponent] = useState(false); // Estado para forzar la recarga
    const [showEvent, setShowEvent] = useState(false);
    const [showAnimalSector, setAnimalSector] = useState(false)

    useEffect(() => {
        const fetchAnimalData = async () => {
            try {
                const response = await animalService.getAnimalbyId(animalId);
                const responseEvent = await animalService.getEventbyId(animalId);
                setAnimal(response);
                setEvents(responseEvent);
            } catch (error) {
                console.error('Error fetching animal data:', error);
            }
        };

        fetchAnimalData();
    }, [animalId, reloadComponent]); // Agrega reloadComponent como dependencia

    const handleEditClick = () => {
        // Implementa la lógica para editar el animal
    };

    const handleAddEventClick = () => {
        setShowCrudEvent(true);
    };

    const handleCloseCrudEvent = () => {
        setShowCrudEvent(false);
    };

    const handleCloseAnimalSector = () => {
        setAnimalSector(false)
    }

    const updateAnimalDetails = async () => {
        try {
            const response = await animalService.getAnimalbyId(animalId);
            setAnimal(response);
            forceReloadComponent();
        } catch (error) {
            console.error('Error updating animal details:', error);
        }
    };

    const forceReloadComponent = () => {
        setReloadComponent(!reloadComponent);
    };

    const handleEventClick = (event) => {
        setShowEvent(!showEvent);
        setSelectedEvent(event);
    };

    const handleCloseEvent = () => {
        setShowEvent(false);
        setSelectedEvent(null);
    };

    const handleShowAnimalSector = () => {
        setAnimalSector(!showAnimalSector)
    }

    const updateEventDetails = async () => {
        try {
            const responseEvent = await animalService.getEventbyId(animalId);
            setEvents(responseEvent);
            forceReloadComponent();
        } catch (error) {
            console.error('Error updating event details:', error);
        }
    };


    const handleDeleteEvent = async (id,values) => {
        try {
            console.log(id)
            const response = await animalService.deleteEventbyId(id)
            console.log(response)
            Swal.fire({
                position: "bottom",
                icon: "success",
                title: "Se elimino correctamante",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                    popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                    `
                },
                hideClass: {
                    popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
                    `
                }
            });
            forceReloadComponent();


        } catch(error) {
            console.error('Error updating event details:', error);
            Swal.fire({
                position: "bottom",
                icon: "error",
                title: "Hubo un error",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                    popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                    `
                },
                hideClass: {
                    popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
                    `
                }
            });




        }

    }

    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-lg-6 animalContainer">
                    <h2>{animal ? animal.name : 'Loading...'}</h2>
                    <p>Tipo: {animal ? animal.type : ''}</p>
                    <p>Descripción: {animal ? animal.description : ''}</p>
                </div>
                <div className="col-lg-6 eventosContainer">
                    <h3>Eventos</h3>
                    <EventTable events={events} forceReloadComponent={forceReloadComponent} updateEventDetails={updateEventDetails}></EventTable>
                    <ul>
                       

                        {showCrudEvent && (
                            <CrudEvent
                                animal={animal}
                                onClose={handleCloseCrudEvent}
                                onUpdateAnimal={updateAnimalDetails}
                            />
                        )}
                    </ul>

                    {showEvent && selectedEvent && (
                        <EventVisualizacion
                            event={selectedEvent}
                            onClose={handleCloseEvent}
                            onUpdate={updateEventDetails}
                        />
                    )}

                    {showAnimalSector && 
                    (<AnimalSector animal={animal} onClose = {handleCloseAnimalSector} onUpdate={updateAnimalDetails}>


                    </AnimalSector>)}


                </div>
            </div>
            <div className="row mt-3">
                <div className="col-lg-12 text-center">
                    <button className="btn btn-primary btnedit" onClick={handleShowAnimalSector} aria-label={"Editar Animal"}>
                        Editar Animal
                    </button>

                    {showCrudEvent ? (
                        <button className="btn btn-primary btnedit" onClick={handleCloseCrudEvent} aria-label={"Cancelar Evento"}>
                            Cancelar evento
                        </button>
                    ) : (
                        <button className="btn btn-primary btnedit " onClick={handleAddEventClick} aria-label={"Añadir evento"}>
                            Añadir evento
                        </button>
                    )}
                </div>
            </div>

            
        </div>
    );
};

export default AnimalDetail;
