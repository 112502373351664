import React, { useState, useEffect } from 'react';
import { EditProfile } from '../EditProfile/EditProfile';
import authService from '../../services/auth.service';
import AnimalList from '../animal/animal.component';
import "../animal/ComponentStyles/Profile.css"


export const Profile = (props) => {
  const [currentUser, setCurrentUser] = useState(props.currentUser || null);
  const [editProfile, setEditProfile] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      if (!props.currentUser) {
        const currentUser = await authService.getCurrentUser();
        setCurrentUser(currentUser);
      }
    };
    getUser();
  }, [props.currentUser]);

  const handleProfileUpdate = (updatedUser) => {
    setCurrentUser(updatedUser);
    setEditProfile(false);
  };

  const handleCloseProfile = () => {
    setEditProfile(false);
  };

  if (!currentUser) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="container containerPerfil">
      <header className="jumbotron">
        <h3>Hola {currentUser.name} ¡Bienvenido a Pet Passport!</h3>
       
      </header>

      <div className="row datosUsuario">
        <div className="">
          <p>
            <strong>Email:</strong> {currentUser.email}
          </p>
        </div>
        <div className="">
          <p>
            <strong>Nombre:</strong> {currentUser.name}
          </p>
        </div>
        <div className="">
          <p>
            <strong>Teléfono:</strong> {currentUser.phone}
          </p>
        </div>

      </div>



      {editProfile ? (
          <button className="btn btn-primary" onClick={handleCloseProfile} aria-label={"Cancelar edicion"}>
            Cancelar Edición
          </button>
        ) : (
          <button className="btn btn-primary" onClick={() => setEditProfile(true)} aria-label={"Editar Perfil"}>
            Editar Perfil
          </button>
        )}




      {editProfile && (
        <EditProfile
          currentUser={currentUser}
          onUpdate={handleProfileUpdate}
          onClose={handleCloseProfile}
        />
      )}

      

      
    </div>


  );
};
