import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Asegúrate de importar axios
import "./PostEditor.css";
import blogService from '../../services/blog.service';
import { DeleteEventSign } from '../animal/AnimalVisualizacion/DeleteEventSign/DeleteEventSign';
import Swal from 'sweetalert2';

export const PostEditor = ({ blog, referencia, onClose, resetBlog }) => {
    const [title, setTitle] = useState(blog.title || '');
    const [subtitle, setSubtitle] = useState(blog.subtitle || '');
    const [content, setContent] = useState(blog.content || '');
    const [image, setImage] = useState(blog.attachments || "https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg");
    const [showDeleteSign, setShowDeleteSign] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);


    const handleButtonSign = (event) => {
        console.log(event)
        setSelectedEvent(event)
        setShowDeleteSign(true);
    };



    const handleDeleteBlog = async (id) => {

        try {
            const response = await blogService.deleteBlog(id)
            Swal.fire({
                position: 'bottom',
                icon: 'success',
                title: 'Se eliminó correctamente',
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                  popup: 'animate__animated animate__fadeInUp animate__faster',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutDown animate__faster',
                },
              });
              
              resetBlog()
              onClose()
            
              


        } catch (err) {
            Swal.fire({
                position: 'bottom',
                icon: 'error',
                title: 'Hubo un error',
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                  popup: 'animate__animated animate__fadeInUp animate__faster',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutDown animate__faster',
                },
              });
            
        }
        
        



    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async () => {

        try {
            const updatedBlog = {
                _id: blog._id,
                title,
                subtitle,
                content,
                completed: true,    
                attachments: [blog.attachments[0]], // Asegúrate de enviar la imagen correctamente
            };

            const response = await blogService.patchBlogs(blog, updatedBlog);

            Swal.fire({
                position: 'bottom',
                icon: 'success',
                title: 'Se edito correctamente',
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                  popup: 'animate__animated animate__fadeInUp animate__faster',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutDown animate__faster',
                },
              });
            resetBlog()
            onClose(); // Cierra el editor después de la actualización
        } catch (error) {
            console.error('Error updating blog:', error);
            alert('Failed to update blog. Please try again.');
        }
    };

    useEffect(() => {
        // Desactiva el scroll cuando el componente se monta
        document.body.style.overflow = 'hidden';

        return () => {
            // Restaura el scroll cuando el componente se desmonta
            document.body.style.overflow = '';
        };
    }, []);

    return (
        <div className='fondo' ref={referencia}>
            <div className="PostEditor">
                <h1>Editar Post</h1>

                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <input
                        type="text"
                        value={subtitle}
                        onChange={(e) => setSubtitle(e.target.value)}
                    />
                    <textarea
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                    />
                    <img className='imgEdit' src={image} alt="Preview" />

                    <label htmlFor="fileInput" className="file-label">
                        Seleccionar Imagen
                    </label>
                    <input
                        type="file"
                        id="fileInput"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />

                </form>

                <div className="BtnBlogContainer">

                    <button className='btnSubirBlog' type="submit" onClick={() => handleSubmit()}>Editar Post</button>

                </div>

                <span className='Cerrar' onClick={onClose}>x</span>
            </div>
            {showDeleteSign && (<DeleteEventSign event={selectedEvent} onDelete={handleDeleteBlog} onClose={() => setShowDeleteSign(false)}></DeleteEventSign>)}
        </div>
    );
}
