import React, { useEffect, useState, useRef } from 'react';
import { format } from 'date-fns';
import "./EventVisualizacion.css";
import animalService from '../../../../services/animal.service';
import Swal from 'sweetalert2';

export const EventVisualizacion = ({ event, onClose, onUpdate }) => {
    const formattedStartDate = format(new Date(event.startDate), "yyyy-MM-dd'T'HH:mm");
    const formattedEndDate = format(new Date(event.endDate), "yyyy-MM-dd'T'HH:mm");
    const displayStartDate = format(new Date(event.startDate), 'dd-MM-yyyy HH:mm');
    const displayEndDate = format(new Date(event.endDate), 'dd-MM-yyyy HH:mm');

    const [editOptions, setEditOptions] = useState(false);
    const [name, setName] = useState(event.name); // Estado para el campo 'name'
    const [description, setDescription] = useState(event.description);
    const [startDate, setStartDate] = useState(formattedStartDate);
    const [endDate, setEndDate] = useState(formattedEndDate);
    const [type, setType] = useState(event.type); // Estado para el campo 'type'

    const [originalName, setOriginalName] = useState(event.name); // Estado para el valor original del campo 'name'
    const [originalDescription, setOriginalDescription] = useState(event.description);
    const [originalStartDate, setOriginalStartDate] = useState(formattedStartDate);
    const [originalEndDate, setOriginalEndDate] = useState(formattedEndDate);
    const [originalType, setOriginalType] = useState(event.type); // Estado para el valor original del campo 'type'

    const nameInputRef = useRef(null); // Referencia para el campo 'name'

    const handleEdit = () => {
        setEditOptions(true);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    const handleTypeChange = (e) => {
        setType(e.target.value);
    };

    const handleCancel = () => {
        setName(originalName);
        setDescription(originalDescription);
        setStartDate(originalStartDate);
        setEndDate(originalEndDate);
        setType(originalType); // Restablecer el valor original del campo 'type'
        setEditOptions(false);
    };

    const handleEditionFinal = async () => {
        const edit = {
            id: event._id,
            name: name,
            type: type,
            startDate: startDate,
            endDate: endDate,
            description: description
        };

        try {
            const response = await animalService.patchEventbyId(event._id, edit);
            console.log(response);
            Swal.fire({
                position: "bottom",
                icon: "success",
                title: "Se guardó correctamente",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                    popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                    `
                },
                hideClass: {
                    popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
                    `
                }
            });
            onClose();
            onUpdate();
        } catch (error) {
            console.error('Error al enviar la solicitud:', error);
            Swal.fire({
                position: "bottom",
                icon: "error",
                title: "Hubo un error",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                    popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                    `
                },
                hideClass: {
                    popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
                    `
                }
            });
        }
    };

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleEscape);

        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [onClose]);

    useEffect(() => {
        if (editOptions && nameInputRef.current) {
            nameInputRef.current.focus();
        }
    }, [editOptions]);

    return (
        <div className='CrudEvent'>
            <h1>{editOptions ? (
                <input
                    type="text"
                    value={name}
                    onChange={handleNameChange}
                    className='inputEditName'
                    ref={nameInputRef}
                />
            ) : (
                name
            )}</h1>

            <div className="ContenidoEvento">
                <div className="descripcion">
                    <div className='titulo'>
                        <label>Descripción:</label>
                    </div>
                    <div className="descriptionText">
                        {editOptions ? (
                            <textarea
                                value={description}
                                onChange={handleDescriptionChange}
                                className='inputEdit'
                            />
                        ) : (
                            <p>{description}</p>
                        )}
                    </div>
                </div>

                <label className='tituloDate'>Tipo:</label>
                <div className="descriptionText eventDates">
                    {editOptions ? (
                        <input
                            type="text"
                            className='inputEdit'
                            value={type}
                            onChange={handleTypeChange}
                        />
                    ) : (
                        <p>{type}</p>
                    )}
                </div>

                <div className='tituloDate'>
                    <label>Fechas:</label>
                </div>
                <div className="descriptionText eventDates">
                    <p>Inicio: {editOptions ? (
                        <input
                            type="datetime-local"
                            value={startDate}
                            onChange={handleStartDateChange}
                            className='inputEdit'
                        />
                    ) : (
                        displayStartDate
                    )}</p>
                    <p>Final: {editOptions ? (
                        <input
                            type="datetime-local"
                            value={endDate}
                            onChange={handleEndDateChange}
                            className='inputEdit'
                        />
                    ) : (
                        displayEndDate
                    )}</p>
                </div>

                <span className='Cerrar' onClick={onClose}>X</span>
            </div>

            {editOptions ? (
                <button className='btn btn-primary btnCancel' onClick={handleCancel} aria-label={"Cancelar"}>Cancelar</button>
            ) : (
                <button onClick={handleEdit} className='btn btn-primary' aria-label={"Editar"}>Editar</button>
            )}

            {editOptions && (
                <button className='btn btn-primary btnEdit' onClick={handleEditionFinal} aria-label={"Guardar"}>Guardar</button>
            )}
        </div>
    );
};
