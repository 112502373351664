import axios from "axios";
import { authHeader } from "./auth-header";


const API_URL= process.env.REACT_APP_API_URL;

class BlogService {

    getBlogs(){
        return axios.get(API_URL + 'blog/', { headers: authHeader() });

    }


    addBlogs(blog, image) {
        
        const formData = new FormData();
    
        console.log(JSON.stringify(blog))
        formData.append('post', JSON.stringify(blog));
    
        formData.append('image', image);
        
    
        
        return axios.post(API_URL + 'blog', formData, {
            headers: {
                ...authHeader(),
                'Content-Type': 'multipart/form-data', 
            }
        });
    }
    
    

    patchBlogs(blog, updatedBlog){
        return axios.patch(API_URL + 'blog/' + blog._id, updatedBlog, {headers: authHeader() });
    }

    deleteBlog(id){
        axios.delete(API_URL + 'blog/' + id, {headers: authHeader()})

    }



}

export default new BlogService()