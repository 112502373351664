import axios from 'axios';
import {authHeader} from './auth-header';

const API_URL= process.env.REACT_APP_API_URL;

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }


  getUserByEmail(email) {
    console.log(email)
    return axios.get(API_URL + 'admin/users/' + email, {headers: authHeader()})
}

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  patchProfileUser(values){
    return axios.patch(API_URL + 'users/me', values, { headers: authHeader() });

  }


}

 export default new UserService();
