import React from 'react'
import './Recupero.css'

export const Recupero = () => {
  return (
    <div className='Recupero'>


        <div className="RecuperarSector">

            <h1>Recuperar contraseña</h1>

            <div className="emailSector">

            <a href="">Ingresa tu mail</a>

<input type="text" />




            </div>

            

            <button className='btn btn-primary btn-block'>Recuperar contraseña</button>




        </div>






    </div>
  )
}
