import React, { useEffect, useState, useRef } from 'react';
import './PublicContent.css';
import { NoLog } from '../noLog/NoLoged';
import { BlogEditorComponent } from '../BlogEditorComponent/BlogEditorComponent';
import blogService from '../../services/blog.service';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { PostEditor } from '../PostEditor/PostEditor';
import { DeleteEventSign } from '../animal/AnimalVisualizacion/DeleteEventSign/DeleteEventSign'; // Asegúrate de importar el componente
import Swal from 'sweetalert2';


export const PublicContent = ({ currentUser }) => {
  const [blogs, setBlogs] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null); // Estado para el blog seleccionado
  const [isPostEditorOpen, setIsPostEditorOpen] = useState(false); // Estado para controlar la visibilidad del PostEditor
  const [showOptions, setShowOptions] = useState(null); // Estado para controlar la visibilidad del menú de opciones
  const [showDeleteSign, setShowDeleteSign] = useState(false); // Estado para controlar la visibilidad del componente DeleteEventSign
  const postEditorRef = useRef(null); // Referencia al PostEditor

  const hasBlogEditorRole = currentUser?.roles?.some(role => role.name === 'blogEditor' || "admin");
  const [isEditOption, setEditOption] = useState(false)

  const fetchBlogs = async () => {
    try {
      const fetchedBlogs = await blogService.getBlogs();
      setBlogs(fetchedBlogs.data);
      console.log(fetchedBlogs.data);
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const resetComponent = () => {
    setBlogs([]);
    setSelectedBlog(null);
    setIsPostEditorOpen(false);
    setShowDeleteSign(false);
    fetchBlogs(); // Vuelve a cargar los blogs si es necesario
  };

  const handleEditOption = () => {
    setEditOption(!isEditOption)
  }

  const handleDeleteBlog = async (id) => {
    try {
        const response = await blogService.deleteBlog(id);
        Swal.fire({
            position: 'bottom',
            icon: 'success',
            title: 'Se eliminó correctamente',
            showConfirmButton: false,
            timer: 2000,
            showClass: {
              popup: 'animate__animated animate__fadeInUp animate__faster',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutDown animate__faster',
            },
        });
        window.location.reload();
    } catch (err) {
        Swal.fire({
            position: 'bottom',
            icon: 'error',
            title: 'Hubo un error',
            showConfirmButton: false,
            timer: 2000,
            showClass: {
              popup: 'animate__animated animate__fadeInUp animate__faster',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutDown animate__faster',
            },
        });
    }
  }

  const handleEditClick = (blog) => {
    setSelectedBlog(blog);
    setIsPostEditorOpen(true); // Abre el PostEditor
    setShowOptions(null); // Oculta el menú de opciones
  };

  const handleDeleteClick = (blog) => {
    setSelectedBlog(blog);
    setShowDeleteSign(true); // Abre el componente DeleteEventSign
    setShowOptions(null); // Oculta el menú de opciones
  };

  const handleOptionsClick = (index) => {
    setShowOptions(showOptions === index ? null : index); // Alterna el menú de opciones
  };

  useEffect(() => {
    if (isPostEditorOpen && postEditorRef.current) {
      postEditorRef.current.scrollIntoView({ behavior: 'smooth' }); // Desplaza hacia el PostEditor
    }
  }, [isPostEditorOpen]);

  var settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  const isYouTubeUrl = (url) => {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    return youtubeRegex.test(url);
  };

  return (
    <div className='PublicContent'>
      <Slider {...settings} className='mySwiper'>
        <img src="https://escuelaversailles.com/wp-content/uploads/auxiliar-de-veterinaria.jpg" alt="" />
        <img src="https://universidadeuropea.com/resources/media/images/medicina-veterinaria-1200x630.original.jpg" alt="" />
        <img src="https://www.portalveterinaria.com/upload/20231115093559vets.jpg" alt="" />
      </Slider>

      <h1>Pet-passport</h1>

      {hasBlogEditorRole && <button className='btn btn-primary' onClick={handleEditOption}>Nueva publicacion</button>}

      {hasBlogEditorRole && isEditOption && <BlogEditorComponent currentUser={currentUser} resetBlog={resetComponent} />}

      {blogs.map((blog, index) => (
        <div key={index} className='ContenidoIzquierda'>
          <div className='contenidoBlog'>
            {blog.attachments && blog.attachments.map((attachment, i) => (
              <div key={i}>
                {isYouTubeUrl(attachment) ? (
                  <iframe
                    width="465"
                    height="315"
                    src={attachment.replace("watch?v=", "embed/")}
                    title={blog.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <img src={attachment} alt={blog.title} />
                )}
              </div>
            ))}
            <div className="TextBlog">
              <h2>{blog.title}</h2>
              <h6>{blog.subtitle}</h6>
              <p>{blog.content}</p>


            
            
            </div>
          </div>

          {hasBlogEditorRole && (
            <div className='optionsContainer'>
              <button onClick={() => handleOptionsClick(index)} className='spanEdit' aria-label='Mas acciones'>Mas acciones</button>
              {showOptions === index && (
                <div className='optionsMenu'>
                  <button onClick={() => handleEditClick(blog)} aria-label='Editar Button'>Editar</button>
                  <button onClick={() => handleDeleteClick(blog)} aria-label='Eliminar Button' className='eliminarBlog'>Eliminar</button>
                </div>
              )}
            </div>
          )}
        </div>
      ))}

      {hasBlogEditorRole && isPostEditorOpen && (
        <div>
          <PostEditor blog={selectedBlog} referencia={postEditorRef} onClose={() => setIsPostEditorOpen(false)} resetBlog={resetComponent} />
        </div>
      )}

      {showDeleteSign && selectedBlog && (
        <DeleteEventSign event={selectedBlog} onDelete={() => handleDeleteBlog(selectedBlog._id)} onClose={() => setShowDeleteSign(false)} />
      )}

      {!currentUser && <NoLog />}
    </div>
  );
};
