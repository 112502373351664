import React from 'react'
import "./Footer.css"

export const Footer = () => {
    return (
        <div className='Footer'>

            <div className="containerInfo">

                <div className="info">

                    <h3>Contacto</h3>

                        <p>Email: ejemplo@gmail.com </p>
                        <p>Telefono: +54 9 351 332-0801 </p>


                </div>

                

                


            </div>




        </div>
    )
}
