import React, { useEffect, useRef } from 'react';
import "./DeleteEventSign.css";

export const DeleteEventSign = ({ event, onDelete, onClose }) => {
  const deleteButtonRef = useRef(null);



  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    if (deleteButtonRef.current) {
      deleteButtonRef.current.focus();
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  return (
    <div className='DeleteEventSign'>
      <h1>Estas seguro de borrar el {event.name ? "evento" : "blog"} </h1>
      <h3>"{event.name ? event.name : event.title}"</h3>

      <div className="buttonsEvent">
        <button
          ref={deleteButtonRef}
          onClick={() => onDelete(event._id)}
          className='btnEliminar'
          aria-label={"Eliminar"}
        >
          Eliminar
        </button>
        <button onClick={onClose} className='btnCancelar' aria-label={"Cancelar"}>Cancelar</button>
      </div>
    </div>
  );
};
